.markdownContent {
  /* General content styling */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Table styling */
.markdownContent table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
}

.markdownContent th {
  background-color: #f2f2f2;
  font-weight: 600;
  text-align: left;
  padding: 8px 12px;
  border: 1px solid #ddd;
}

.markdownContent td {
  padding: 8px 12px;
  border: 1px solid #ddd;
}

.markdownContent tr:nth-child(even) {
  background-color: #f9f9f9;
}

.markdownContent tr:hover {
  background-color: #f2f2f2;
}

/* Additional markdown element styling */
.markdownContent code {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
}

.markdownContent pre {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}

.markdownContent blockquote {
  border-left: 4px solid #ddd;
  padding-left: 1rem;
  margin-left: 0;
  color: #666;
}
